<template>
  <v-container fluid class="mt-3">
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              v-model="filter"
              clearable
              :label="$t('search')"
              hide-details
              outlined
              dense
              @keyup="filterKeyup"
            >
            </v-text-field>
          </v-col>

          <!-- <v-col cols="12" md="2" sm="4">
            <v-dialog
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filterModel.fromDate"
                  hide-details
                  outlined
                  dense
                  :label="$t('fromDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterModel.fromDate"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2" sm="4">
            <v-dialog
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="filterModel.toDate"
                  :label="$t('toDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterModel.toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col> -->
          <v-col>
            <v-btn block elevation="0" color="primary" @click="refreshTable()">
              {{ $t("search") }}
              <v-icon> mdi-magnify </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card outlined class="pa-5 mt-3">
      <data-table-component
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";

export default {
  components: { DataTableComponent },

  data() {
    return {
      filterModel: {
        patientName: "",
        doctorGuid: "",
        fromDate: "",
        toDate: "",
        billLock: 0,
        billPaid: 0,
      },
      search: "",
      loading: false,
      menu1: false,
      menu2: false,
      filter: "",

      items: [],
      page: 1,
    };
  },
  created() {
    this.refreshTable();
    this.filterModel.fromDate = this.$moment().format("YYYY-MM-DD");
    this.filterModel.toDate = this.$moment().format("YYYY-MM-DD");
  },
  computed: {
    headers() {
      var list = [
        {
          text: this.$t("patients.patientName"),
          value: "patientDisplayName",
        },

        {
          text: this.$t("patients.patientCategory"),
          value: "categories",
        },
        {
          text: this.$t("patientHistory.initialDiagnosis"),
          value: "initialDiagnosis",
        },
        {
          text: this.$t("patientHistory.finalDiagnosis"),
          value: "finalDiagnosis",
        },
        // { text: this.$t("notes"), value: "note" },
        {
          text: this.$t("date"),
          value: "dateReg",
          type: "date",
          format: "yyyy-MM-DD hh:mm a ",
        },

        // {
        //   text: this.$t("tests.testPrice"),
        //   value: "testPrice",
        //   type: "number",
        //   format: "0,0",
        // },
      ];

      return list;
    },

    billLockFilter() {
      return [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.locked"), value: 1 },
        { text: this.$t("bills.notLocked"), value: 2 },
      ];
    },
    billPaidFilter() {
      return [
        { text: this.$t("bills.both"), value: 0 },
        { text: this.$t("bills.paided"), value: 1 },
        { text: this.$t("bills.notPaided"), value: 2 },
      ];
    },
  },
  methods: {
    refreshTable() {
      this.loading = true;

      this.$axios
        .get("Reports/PatientHistoryReport?filter=" + this.filter)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((e) => {
          this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterKeyup(key) {
      if (key.keyCode == 13) {
        this.refreshTable();
      }
    },
  },
};
</script>

<style></style>
